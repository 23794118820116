//
// user.scss
// Use this to write your custom SCSS
//

@import './toastr-notifications.scss';

.main-content.collapsed {
  @include media-breakpoint-up(md) {
    margin-left: 65px !important;
  }
}

.popup-content {
  background: #fff;
  border: 1px solid rgba(44, 123, 129, 0.3);
  padding: 1rem;
  max-width: 400px;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  .popup-arrow {
    stroke: rgba(44, 123, 129, 0.3);
  }
}

.modal-dialog-vertical {
  max-width: 500px;
  min-width: 350px;
}

.text-small {
  font-size: 0.75rem;
}

.form-control:disabled {
  border: none;
}

.big-checkbox input {
  width: 2rem;
  height: 2rem;
}

.aa-Panel {
  z-index: 9999;
}
