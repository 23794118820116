//
// buttons.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

// Sizing

.btn-lg {
  line-height: $btn-line-height-lg;
}

.btn-sm {
  line-height: $btn-line-height-sm;
}

.btn {
  svg {
    padding-right: 4px;
  }
}

//
// Theme ===================================
//

// Button white

.btn-white {
  border-color: $gray-300;

  &:hover,
  &:focus {
    background-color: $gray-100;
    border-color: $gray-400;
  }
}

.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active,
.btn-group-toggle .btn-check:checked + .btn-white {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  box-shadow: none;
  color: $primary;
}

.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}

// Button white 20

.btn-white-20 {
  background-color: rgba($white, 0.2);
  border-color: transparent;
  color: $white;

  &:hover,
  &:focus {
    background-color: rgba($white, 0.12);
    border-color: transparent;
    color: $white;
  }
}

// Button outline secondary

.btn-outline-secondary {
  &:not(:hover):not([aria-expanded='true']):not([aria-pressed='true']) {
    border-color: $gray-400;
  }
}

// Button rounded
//
// Creates circle button variations

.btn-rounded-circle {
  width: calc(1em * #{$btn-line-height} + #{$input-btn-padding-y * 2} + #{$btn-border-width} * 2);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn-rounded-circle.btn-lg {
  width: calc(
    1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2} + #{$btn-border-width} * 2
  );
}

.btn-rounded-circle.btn-sm {
  width: calc(
    1em * #{$btn-line-height-sm} + #{$input-btn-padding-y-sm * 2} + #{$btn-border-width} * 2
  );
}

// Button group
//
// Prevent buttons from jittering on hover

.btn-group .btn + .btn {
  margin-left: 0;
}
