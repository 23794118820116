@keyframes opacityFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translate(0, -150px);
  }

  100% {
    transform: translate(0, 0px);
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
