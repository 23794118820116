//
// reboot.scss
// Extended from Bootstrap
//

html {
  height: 100%;
}

// Lists

ul,
ol {
  padding-left: 2.5rem;
}

//
// Remove the cancel buttons in Chrome and Safari on macOS.
//

[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
